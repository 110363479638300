export const STAGE_OPTIONS = [
  {
    value: null,
    text: 'Chọn môi trường',
  },
  {
    value: 'dev',
    text: 'Development',
  },
  {
    value: 'sta',
    text: 'Staging',
  },
  {
    value: 'pro',
    text: 'Production',
  },
];

export const TABLE_FIELDS = [
  {
    key: 'id',
    label: 'ID',
    sortable: false,
    thClass: 'table-header',
  },
  {
    key: 'name',
    label: 'Tên',
    sortable: false,
    tdClass: 'td-type',
    thClass: 'table-type-header',
  },
  {
    key: 'status',
    label: 'Trạng thái',
    sortable: false,
    tdClass: 'td-type',
    thClass: 'table-type-header',
  },
  {
    key: 'stage',
    label: 'Giai đoạn',
    sortable: false,
    tdClass: 'td-type',
    thClass: 'table-type-header',
  },
  {
    key: 'details',
    label: 'Cấu hình',
    sortable: false,
    tdClass: 'td-type',
    thClass: 'table-type-header',
  },
  {
    key: 'actions',
    label: 'Thao tác',
    sortable: false,
    tdClass: 'td-type',
    thClass: 'table-type-header',
  }
];

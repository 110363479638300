import { CreateFeatureFlagDetailDto } from '@/view/pages/feature-flags/models/CreateFratureFlagDetailDto';

export class CreateFeatureFlagDto {
  constructor(data) {
    this.name = data.name;
    this.featureType = !isNaN(Number(data.featureType))
      ? parseInt(data.featureType)
      : 0;
    this.stage = data.stage || null;
    this.status = Boolean(data.status);
    this.details = data.details
      ? new CreateFeatureFlagDetailDto(data.details)
      : new CreateFeatureFlagDetailDto({});
  }
}
